.home {
  .brand {
    &__box {
      padding: 10rem 0;
    }

    &__title {
      font-size: 3rem;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 2rem;
      color: black;
    }

    &__message {
      font-size: 2rem;
      text-align: justify;
    }
  }

  .quotes {
    .container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 2.5rem;
      row-gap: 2.5rem;
      padding-top: 7.5rem;
      padding-bottom: 15rem;

      @include respond(sm) {
        grid-template-columns: 1fr 1fr;
        padding-bottom: 10rem;
      }
    }

    .heading-2 {
      grid-column: 1/-1;
      margin-bottom: 2.5rem;
      text-align: center;
    }
  }

  .explores {
    .container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 48.8rem 30rem;
      row-gap: 3rem;
      column-gap: 3rem;
      padding-bottom: 7.5rem;

      @include respond(md) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
      }

      @include respond(sm) {
        grid-template-columns: 1fr;
        padding-bottom: 5rem;
      }
    }
  }

  .services {
    .container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      padding-top: 7.5rem;
      padding-bottom: 7.5rem;
      column-gap: 2.5rem;
      row-gap: 2.5rem;
      text-align: center;

      @include respond(sm) {
        grid-template-columns: 1fr 1fr;
        padding-top: 5rem;
        padding-bottom: 5rem;
      }
    }
  }

  .textBlock {
    .container {
      padding-top: 7.5rem;
      padding-bottom: 7.5rem;
    }
  }

  .home-logo {
    text-align: center;

    .container {
      padding: 15rem 20rem;

      @include respond(lg) {
        padding-left: 8rem;
        padding-right: 8rem;
      }

      @include respond(md) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }

    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      row-gap: 2rem;
      column-gap: 2rem;
      margin-bottom: 5rem;

      @include respond(sm) {
        grid-template-columns: 1fr 1fr;
      }
    }

    &__title {
      margin-bottom: 5rem;
    }

    .logo-card:not(:nth-child(-n + 4)) {
      display: none;
    }
  }
}

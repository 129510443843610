.masonry {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
  padding: 5rem 0;

  @include respond(md) {
    grid-template-columns: repeat(2, 1fr);
  }

  &__block {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    height: 40rem;
    background-color: black;
    transition: all 0.3s;

    &:hover {
      transform: scale(1.01);
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    opacity: 0.8;
  }

  &__cover {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $color-primary;
    opacity: 0;
    transition: all 0.5s ease;
  }

  &__block:hover &__cover {
    opacity: 0.8;
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 3rem;
    color: white;
  }

  &__title {
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: bold;

    &::after {
      content: "";
      display: block;
      height: 2px;
      width: 0;
      background-color: white;
      margin-top: 1rem;
      margin-bottom: 1rem;
      transition: all 0.4s;
    }
  }

  &__text {
    font-size: 1.5rem;
    font-weight: 300;
    transform: translateX(-100%);
    opacity: 0;
    transition: all 0.4s;
  }

  &__block:hover &__title::after {
    width: 100%;
  }

  &__block:hover &__text {
    transform: translateX(0);
    opacity: 1;
  }
}

.about {
  .text-block {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  &__img {
    height: 50rem;

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }
}

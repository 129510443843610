.logo-card {
  height: 20rem;
  perspective: 2000px;

  &__enter {
    animation: zoomIn;
    animation-duration: 0.5s;
    animation-timing-function: ease;
  }

  @include respond(sm) {
    height: 25rem;
  }

  &__inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  &:hover &__inner {
    transform: rotateY(180deg);
  }

  &__front,
  &__back {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    color: white;
    border-radius: 10px;
  }

  &__back {
    background-image: linear-gradient(to right bottom, #72c1a7, #55b495);
    flex-direction: column;
    transform: rotateY(180deg);
    padding: 2rem;
    font-size: 1.4rem;
  }

  &__logo {
    width: 15rem;
  }

  &__name {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.6);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes shake {
  0% {
    left: 0;
  }
  1% {
    left: -3px;
  }
  2% {
    left: 5px;
  }
  3% {
    left: -8px;
  }
  4% {
    left: 8px;
  }
  5% {
    left: -5px;
  }
  6% {
    left: 3px;
  }
  7% {
    left: 0;
  }
}

.text-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;

  @include respond(sm) {
    grid-template-columns: 1fr;
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__content {
    font-size: 2rem;
    font-weight: 300;
    text-align: justify;
  }
}

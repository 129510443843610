.blg {
  &__container {
    padding-bottom: 15rem;
    padding-top: 15rem;
    line-height: 3rem;
    font-size: 1.8rem;
    text-align: justify;
  }

  &__title {
    font-size: 2.8rem;
    margin-bottom: 1rem;
  }

  p:not(:last-child) {
    margin-bottom: 2rem;
  }

  &__media {
    margin-top: 7rem;
    margin-bottom: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
  }

  &__list {
    margin-left: 5rem;
  }

  .gewichten {
    width: 80%;
  }

  figcaption {
    text-align: center;
  }

  .gif {
    width: 60%;
  }

  .blg__video iframe {
    width: 100%;
    height: 100%;
  }

  &__media &__logos {
    width: 30%;
  }
}

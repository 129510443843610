.explore {
  &__box {
    overflow: hidden;
    position: relative;
    cursor: pointer;
    border-radius: 10px;
    transition: all 0.2s;
    backface-visibility: hidden; /* add to fix webkit bug jitter */
    transform: perspective(1000px); /* add to fix webkit bug jitter */

    @include respond(md) {
      height: 40rem;
    }

    @include respond(sm) {
      height: 30rem;
    }

    &:hover {
      transform: scale(1.01);
    }

    &--1 {
      grid-row: 1/2;
      grid-column: 1/3;

      @include respond(md) {
        grid-row: auto;
        grid-column: auto;
      }
    }

    &--2 {
      grid-column: 3/4;
      grid-row: 1/3;

      @include respond(md) {
        grid-row: auto;
        grid-column: auto;
      }
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 12rem;
    padding: 3rem;
    color: white;
  }

  &__title {
    box-decoration-break: clone;
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 1rem;

    &::after {
      margin-top: 1rem;
      content: "";
      display: block;
      height: 2px;
      width: 0px;
      background-color: white;
      transition: all 0.4s;
    }
  }

  &__text {
    font-size: 1.8rem;
    font-weight: 300;
    transform: translateX(-100%);
    opacity: 0;
    text-align: justify;
    transition: all 0.4s;
  }

  &__img {
    display: block;
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  &__box:hover &__title::after {
    width: 100%;
  }

  &__box:hover &__text {
    transform: translateX(0);
    opacity: 1;
  }

  &__circle {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $color-primary;
    opacity: 0;
    transition: all 0.5s ease;
  }

  &__box:hover &__circle {
    opacity: 0.8;
  }
}

.edgard {
  &__container {
    padding-bottom: 15rem;
    padding-top: 15rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5rem;
    line-height: 3rem;
    font-size: 1.8rem;
    text-align: justify;
  }

  &__left {
    display: grid;
    row-gap: 5rem;
  }

  img {
    width: 100%;
  }

  &__title {
    font-size: 2.8rem;
    margin-bottom: 1rem;
  }

  p:not(:last-child) {
    margin-bottom: 2rem;
  }
}

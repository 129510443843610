.cookie {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  z-index: 100;
  box-shadow: 0 -1px 10px 0 rgba(172, 171, 171, 0.3);
  .container {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 2rem;
    align-items: center;
  }

  &__title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  &__info {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    width: 50rem;
    text-align: center;
  }

  .btn {
    font-size: 1.8rem;
  }
}

.menu {
  width: 100%;
  height: 0;
  z-index: 1000;
  position: fixed;

  a {
    text-decoration: none;
    color: black;
  }

  &__checkbox {
    display: none;
  }

  &__background {
    background-color: white;
    height: 10rem;
    width: 100%;
    transform: translateY(-100%);
    transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);
    z-index: 1000;
    box-shadow: 0 4px 20px -9px rgba(13, 12, 40, 0.1);

    @include respond(sm) {
      height: auto;
    }
  }

  &__btn {
    position: fixed;
    width: 5rem;
    height: 5rem;
    top: 5rem;
    right: 3rem;
    border-radius: 50%;
    transform: translateY(-50%);
    background-color: white;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    cursor: pointer;
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
  }

  &__btn:hover &__icon::after {
    transform: translateY(2px);
  }

  &__btn:hover &__icon::before {
    transform: translateY(-2px);
  }

  &__icon {
    position: relative;
    &,
    &::before,
    &::after {
      background-color: black;
      display: block;
      width: 2.5rem;
      height: 3px;
      border-radius: 100px;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      transition: all 0.2s;
    }

    &::after {
      bottom: -0.9rem;
    }

    &::before {
      top: -0.9rem;
    }
  }

  &__logo {
    height: 5rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 3rem;

    @include respond(sm) {
      transform: translate(-50%, -50%);
      top: 4rem;
      left: 50%;
    }
  }

  &__nav {
    display: flex;
    height: 100%;
    justify-content: center;
    align-content: center;

    @include respond(sm) {
      padding-top: 8rem;
    }
  }

  &__list {
    font-size: 1.8rem;
    font-weight: bold;
    display: flex;
    list-style: none;
    text-transform: uppercase;
    z-index: 1500;

    @include respond(sm) {
      flex-direction: column;
    }

    li {
      text-align: center;
      padding-bottom: 0.25rem;

      &::after {
        content: "";
        display: block;
        background-color: $color-primary;
        height: 2px;
        width: 0;
        transition: all 0.2s;
      }
    }

    .active li::after,
    li:hover::after {
      width: 100%;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      @include respond(sm) {
        margin-bottom: 1rem;
      }
    }

    a:not(:last-child) {
      margin-right: 2rem;
      @include respond(sm) {
        margin-right: 0;
      }
    }
  }

  &__checkbox:checked ~ &__background {
    transform: translateY(0);
  }

  &__checkbox:checked ~ &__btn {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  }

  &__checkbox:checked ~ &__btn &__icon {
    background-color: transparent;
  }

  &__checkbox:checked ~ &__btn &__icon::after {
    transform: rotate(-135deg);
    top: 0;
  }

  &__checkbox:checked ~ &__btn &__icon::before {
    top: 0;
    transform: rotate(135deg);
  }
}

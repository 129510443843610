.heading-1 {
  font-size: 4.5rem;
  font-weight: 700;
  text-transform: uppercase;
}

.heading-2 {
  font-size: 3.5rem;
  font-weight: bold;
  text-transform: uppercase;
}
